import React, { useEffect, useState } from "react"
import { Row, Col } from "antd"

import RHRLogo from "../../assets/images/RHR_logo.png"
import FiliLogo from "../../assets/images/brands/Fili.png"
import GoLogo from "../../assets/images/brands/go_logo_colored.png"
import GsgLogo from "../../assets/images/brands/GSG_logo.png"
import SHRLogo from "../../assets/images/brands/SHR_LOGO.png"

import { BrandsI } from "../../interface"
import { STATUS } from "../../enum"

import "./BrandsHierarchy.scss"
import { brandBategories } from "../FindaHotel/initailValue"

interface HotelsDetailsI {
  label: string,
  details: Array<HotelHierarchy>,
  id: number
}

interface HotelHierarchy{
  img: any,
  link: string
}

const BrandsHierarchy = ({ pageContext }) => {

const [brands, setBrands] = useState<BrandsI[]>([])
const [hotels, setHotels] = useState<HotelsDetailsI[]>([])

  useEffect(() => {
    setBrands(pageContext.brands);
    setHotels(pageContext.hotels);

    const categories = Array.from(
      new Set(
        brands
          .filter(
            brand =>
              brand.status === STATUS.PUBLISHED && brand.hotels.length !== 0
          )
          .map(brand => brand.category["name"])
      )
    ).map(category => {
      return brands.find(brand => brand.category["name"] === category)
    })

    //get all available brand category
    categories.map(category => {
      const hotelHierarchy: HotelHierarchy[] = []

      //collect all hotel with the same category
      brands
        .filter(brand => brand.status === STATUS.PUBLISHED)
        .filter(brand => brand.category["name"] === category?.category["name"])
        .map((brand) => {
          hotelHierarchy.push({
            img: brand.uploaded_media.filter((b) => b.uploaded_media_type.name === "category_logo").map(b => {return b.url}),
            link: brand.slug,
          })
        })
      
      //brand hierarchy data
      hotels.push({
        label: category?.category["name"],
        details: hotelHierarchy,
        id: brandBategories.filter(
          brandCat =>
            brandCat.category === category?.category["name"].toUpperCase()
        )[0].id,
      })

      //sort array object based on id
      hotels.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0))
    })

    setHotels([...hotels])

  }, [brands]);

  const onErrorImg = (e) => {
    e.target.src = RHRLogo;
}

  return (
    <div className="brand-heirarchy-contanier paddingpX50">
      <div className="brand-heirarchy-wrap">
        <img
          src={RHRLogo}
          alt="RHR LOGO"
          className="brand-rhr-logo img-responsive"
        />
        <h3 className="text-center">LOCAL & INTERNATIONAL BRANDS</h3>
        <div className="brands-list">
          {hotels.map((hotel,index) => (
            <div className="brand-card" key={index}>
              <h4 className="title-border">{hotel.label}</h4>
              {hotel.details.map((detail,key) => (
                <a href={`/brand/${detail.link}`} style={detail.link === "" ? {pointerEvents: "none"} : {}} target="_blank" key={key}>
                  <img src={detail.img} onError={onErrorImg} alt="Brands LOGO" className="img-responsive" />
                </a>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default BrandsHierarchy
