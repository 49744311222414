import React, { useState, useEffect } from "react"
import { Row, Col, Carousel } from "antd"

import ChevronRed from "../../assets/images/ic_chevron_right_24px_red.svg"
import { BrandsI } from "../../interface"

import "./BrandsCarousel.scss"

const BrandCarousel = ({ data, pageContext }) => {
  const [brands, setBrands] = useState<BrandsI[]>()
  const [infinite, setInfinite] = useState<boolean>(false)
  const [ogWidth, setOgWidth] = useState<string>("")
  const [notHoverWidth, setNotHoverWidth] = useState<number>()
  const [hoverWidth, setHoverWidth] = useState<number>()
  const [isMobileView, setIsMobileView] = useState<boolean>(false)
  useEffect(() => {
    const { brands: pageBrands } = pageContext
    setBrands(
      pageBrands.sort((a, b) =>
        a.order > b.order ? 1 : b.order > a.order ? -1 : 0
      )
    )
    let count = pageBrands?.filter(b => b.status === "PUBLISHED").length
    setInfinite(count && count >= 6 ? true : false)
  }, [brands])

  const carouselSlidesWidth = () => {
    if (typeof window !== "undefined") {
      const ele_card = Array.from(
        document.querySelectorAll(
          ".brands-carousel-container .slick-track .slick-slide"
        )
      )

      if (ele_card) {
        const ele_children = document.querySelector(
          ".brands-carousel-container .slick-track > .slick-slide"
        )

        setOgWidth(ele_children?.clientWidth + `px`)

        if (ele_children) {
          setNotHoverWidth(
            ele_children.clientWidth -
              (ele_children.clientWidth / 2 / ele_card.length < 5
                ? 4
                : ele_card.length)
          )
          setHoverWidth(ele_children.clientWidth + ele_children.clientWidth / 2)
        }
      }
    }
  }

  useEffect(() => {
    setIsMobileView(window.innerWidth < 812 ? true : false)
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 812 ? true : false)
    }
    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const onEnterCard = (e: any) => {
    carouselSlidesWidth()

    document
      .querySelectorAll(
        '.brands-carousel-container .slick-track .slick-slide.slick-active[aria-hidden="false"]'
      )
      .forEach(el => {
        ;(el as HTMLInputElement).style.width = notHoverWidth + "px"
      })

    const target = e.currentTarget.parentNode.parentNode
    target.style.width = hoverWidth + "px"
  }

  const onLeaveCard = (e: any) => {
    const target = e.currentTarget.parentNode.parentNode
    target.style.width = ogWidth
    document
      .querySelectorAll(
        '.brands-carousel-container .slick-track .slick-slide.slick-active[aria-hidden="false"]'
      )
      .forEach(el => {
        ;(el as HTMLInputElement).style.width = ogWidth
      })
  }

  const carouselSetting = {
    slidesToShow: 5,
    draggable: true,
    infinite: infinite,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 812,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  }

  return (
    <div className="brands-container paddingpX50">
      <Row>
        <Col style={{ padding: "3rem 0 0 3rem" }} xl={24} xs={21}>
          <article className="text-center our-brands">
            <span>OUR</span>
            <h2 className="title-border">BRANDS</h2>
          </article>
          <p className="text-center" style={{ width: "100%" }}>
            Find the perfect stay with us with our diverse portfolio of
            International and Homegrown brands. Robinsons Hotels and Resorts
            strives to create genuine and heartfelt Filipino service culture to
            every kind of traveler.
          </p>
        </Col>
        <Col
          span={24}
          className="brands-carousel-container"
          id="brandCarouselHome"
        >
          <Carousel {...carouselSetting}>
            {brands
              ?.filter(b => b.status === "PUBLISHED")
              .map((brand, key) => (
                <div
                  key={key}
                  className="brand-card"
                  onMouseEnter={!isMobileView ? e => onEnterCard(e) : undefined}
                  onMouseLeave={!isMobileView ? e => onLeaveCard(e) : undefined}
                >
                  <a
                    href={brand.redirect_link}
                    target="_blank"
                    style={
                      brand.redirect_link === ""
                        ? { pointerEvents: "none" }
                        : {}
                    }
                  >
                    {brand.uploaded_media
                      .filter(
                        b => b.uploaded_media_type.name === "carousel_image"
                      )
                      .map((b, key) => (
                        <img
                          key={key}
                          className="brand-featured-img"
                          src={b.url}
                          alt="Carousel Image"
                        />
                      ))}
                    <div className="brand-details">
                      <img
                        key={key}
                        className="brand-logo"
                        src={brand.logo}
                        alt="Brand Logo"
                      />
                      <p>{brand.short_description}</p>
                      {brand.redirect_link !== "" && (
                        <span className="know-more">
                          Know More <img src={ChevronRed} alt="arrow right" />
                        </span>
                      )}
                    </div>
                  </a>
                </div>
              ))}
          </Carousel>
        </Col>
      </Row>
    </div>
  )
}

export default BrandCarousel
