import React, { useEffect, useState } from "react"
import { Row, Col } from "antd"

import "./UpdatesAndAnnouncements.scss"
import CarouselFiveItems from "../CarouselFiveItems/CarouselFiveItems"

const UpdatesAndAnnouncements = ({ data }) => {
  const [updatesAndAnnouncements, setUpdatesAndAnnouncements] = useState([]);

  useEffect(() => {
    setUpdatesAndAnnouncements(data.tarsiergraphql.getUpdatesAndAnnouncements);
  }, [updatesAndAnnouncements]);


  return (
    <div className="updates-container">
      <Row className="paddingpX50 updates-details">
        <Col span={24} style={{ paddingLeft: "1.5rem" }}>
          <span>Updates &</span>
          <h2 className="title-border">Announcements</h2>
        </Col>
        <Col span={24}>
          <CarouselFiveItems
            className="updates-announcements"
            data={updatesAndAnnouncements}
          />
        </Col>
      </Row>
    </div>
  )
}

export default UpdatesAndAnnouncements
