import * as React from "react"
import { Layout } from "antd"
import { graphql } from "gatsby"

import MainLayout from "../../Layouts/layoutLanding"
import Seo from "../../components/seo"
import HomeMainCarousel from "../../components/HomeMainCarousel/HomeMainCarousel"
import RHRDescription from "../../components/RHRDescription/RHRDescription"
import FeatureContents from "../../components/FeaturedContent/FeaturedContents"
import BrandsCarousel from "../../components/BrandCarouselHome/BrandsCarousel"
import SearchMeetingPlace from "../../components/SearchMeetingPlace/SearchMeetingPlace"
import FeaturePromotion from "../../components/FeaturePromotion/FeaturePromotion"
import UpdatesAndAnnouncements from "../../components/UpdatesAndAnnouncements/UpdatesAndAnnouncements"
import RHRSafety from "../../components/RHRSafety/RHRSafety"
import BrandsHierarchy from "../../components/BrandHierarchy/BrandsHierarchy"

const IndexPage = ({ data, pageContext }) => (
  <MainLayout pageContext={pageContext} title="homepage">
    <Seo
      title="Home"
      description="Robinsons Hotels and Resort. No matter what your purpose of staying, you have a home with us. Find the perfect hotel."
      lang="en"
    />
    <Layout
      className="carousel"
      style={{ backgroundColor: "black", marginTop: "0" }}
    >
      <HomeMainCarousel data={data} />
      <RHRDescription data={data} />
    </Layout>
    <Layout className="featured-content">
      <FeatureContents />
    </Layout>
    <Layout className="brand-carousel">
      <BrandsCarousel data={data} pageContext={pageContext} />
    </Layout>
    <Layout className="search-meeting">
      <SearchMeetingPlace data={data} />
    </Layout>
    <Layout className="featured-promotion">
      <FeaturePromotion data={data} pageContext={pageContext} />
    </Layout>
    <Layout className="updates-announcements">
      <UpdatesAndAnnouncements data={data} />
    </Layout>
    {/* currently on displayed none on css due to breaking of layout if totally removed from layout */}
    <Layout className="rhr-safety">
      <RHRSafety />
    </Layout>
    <Layout className="brand-heirarchy">
      <BrandsHierarchy pageContext={pageContext} />
    </Layout>
  </MainLayout>
)

export default IndexPage

export const query = graphql`
  query GetHotels($search: String!) {
    tarsiergraphql {
      getHotels(search: $search) {
        id
        website_logo
        address
        name
        website_link
        partner_hotel_code
        booking_engine_link
        group_booking_link
        request_for_proposal_link
        meetings_and_celebrations_link
        brand_id
        partner_hotel_code
        slug
        brand {
          id
          name
          status
          slug
        }
        created_by
        created_at
        updated_at
        updated_by
      }
      getBrands(search: "") {
        id
        logo
        name
        status
        slug
        order
        short_description
        category {
          id
          name
        }
        hotels {
          id
          name
          website_logo
          address
          website_link
          partner_hotel_code
          booking_engine_link
          group_booking_link
          request_for_proposal_link
          meetings_and_celebrations_link
          partner_hotel_code
          created_at
          created_by
          updated_at
          updated_by
        }
        redirect_link
        uploaded_media {
          uploaded_media_type {
            name
            description
          }
          url
        }
      }
      getHeroBanners(search: "") {
        id
        title
        location
        status
        description
        order
        created_by
        updated_by
        created_at
        updated_at
        deployed_by
        deployed_at
        media_hero_banner {
          id
          platform
          url
          media_type
        }
      }
      getPromosAndPackages(search: "") {
        title
        short_description
        updated_by
        deployed_by
        redirect_link
        status
        photo
      }
      getUpdatesAndAnnouncements(search: "") {
        id
        title
        short_description
        redirect_link
        photo_url
        status
      }
      getFooter {
        footnote_title
        footnote_body
        description
      }
    }
  }
`
