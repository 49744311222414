import React, { useEffect, useState } from "react"
import { Row, Col, Carousel } from "antd"

import chevronRed from "../../assets/images/ic_chevron_right_24px_red.svg"
import bannerImageLight from "../../assets/images/GOH-Lifestyle.jpg"
import gohImage from "../../assets/images/GOH-Lifestyle_Corporate.jpg"
import familyImage from "../../assets/images/IMG_9607.jpg"
import family from "../../assets/images/family.png"

import { PromosI } from "../../interface"

import { STATUS } from '../../enum'

import "./FeaturePromotion.scss"


/**
 * Effect of this is detailed here: https://github.com/akiran/react-slick/issues/922
 * Computation for the touch sensitivity threshold: (1/touchThreshold) * the width of the slider
 * default value when unspecified is 5
 * setting to 25 to basically say as soon as 1/4 yung lalabas transition kagad
 */


const CarouselCard = ({
  title, photo, short_description, redirect_link
}) => {
  return (
    <div className="featured-promotion-card">
      <div className="featured-promotion-wrap">
        <div className="featured-promotion-image">
          <img src={photo} alt="Family" className="img-responsive" />
        </div>
        <div className="featured-promotion-card-details">
          <h3>{title}</h3>
          <p>
            {short_description}
          </p>
          <a
            className="know-more"
            href={redirect_link}
            target="_blank"                >
            Know More <img src={chevronRed} alt="arrow right" />
          </a>
        </div>
      </div>
    </div>
  )
}



const touchSensitivityThreshold = 25

const FeaturePromotion = ({ data }) => {

  const [promosAndPackages, setPromosAndPackages] = useState<PromosI[]>();
  const [infiniteSwitch, setInfiniteSwitch]= useState<boolean>(false); 
  useEffect(() => {
    setPromosAndPackages(data.tarsiergraphql.getPromosAndPackages);
    let count = data.tarsiergraphql.getPromosAndPackages?.filter((promo) => promo.status === "PUBLISHED").length;
    setInfiniteSwitch(count && count >= 3 ? true : false);
  }, [promosAndPackages])

  const [slideShow, setSlideShow] = useState<number>();

 useEffect(() => {
        setSlideShow(window.innerWidth > 812 ? 3:1);
        const handleResize = () => {
          setSlideShow(window.innerWidth > 812 ? 3:1);
        };
        window.addEventListener("resize", handleResize);
        
        return () => window.removeEventListener("resize", handleResize);
    }, []);





  return (
    <div className="featured-promotion-container">

      <Row className="">


<Col xl={8} className="featured-promotion-details">
          <article>
            <span>Featured Promotions</span>
            <h2 className="title-border">Offers Curated For You</h2>
            <p>
              From luxurious staycations, to adventures, to family getaways,
              choose an exclusive offer that's perfect for you.
            </p>
          </article>
        </Col>
        <Col xs={35} sm={30} lg={25} xl={16} className="featured-promotion-carousel">
          <Carousel
            draggable={true}
            infinite={infiniteSwitch}
            arrows
            slidesToShow={slideShow}
            slidesToScroll={1}
            touchThreshold={touchSensitivityThreshold}
            className="featured-promotion-list"
          >
            {promosAndPackages?.filter((promo) => promo.status === STATUS.PUBLISHED).map((promo, key) => (
              <CarouselCard key={key}
                title={promo.title}
                short_description={promo.short_description}
                photo={promo.photo}
                redirect_link={promo.redirect_link}
              />
            ))}
          </Carousel>
        </Col>
       
      </Row>
    </div>
  )
}

export default FeaturePromotion
