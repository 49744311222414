import React, { useEffect, useState } from 'react'

import { FooterI } from '../../interface'
import FindaHotel from "../../components/FindaHotel/FindaHotel"

import ChevronRight from '../../assets/images/ic_chevron_right_24px_white.svg'

import './RHRDescriptionAndForm.scss'


const RHRDescription = ({ data }) => {
  const [rhrDescription, setRhrDescription] = useState<FooterI[]>([])

  useEffect(() => {
    setRhrDescription(data.tarsiergraphql.getFooter);
  }, [rhrDescription]);

  return (
    <div className="rhr-container">
      <div className="rhr-description">
 
        <h2 className="title-border">Soaring to new heights</h2>
        <span>Authentic. Exceptional. World-Class</span>
        <div className="description"> {rhrDescription[0]?.description} </div>
        <a href="/about-us#who-we-are">Know More <img src={ChevronRight} alt="Chevron Right" /></a>
      </div>
      <FindaHotel 
        data={data} 
        dropdownClassName="certain-category-search-dropdown"
        placeholder="Hotel Location: e.g. Cebu, Manila"
        containerId="findhotelSideform"
        formID="formHotel1"
        bookingFormStatus={true} />
    </div>
  )
}

export default RHRDescription
