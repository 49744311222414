import React, { useState, useRef, useEffect } from 'react'
import { Carousel } from 'antd'
import { lowerCase } from "lodash";

import bannerImage from '../../assets/images/hero-banner-image.png'
import bannerOverlay from '../../assets/images/Overlay-Carousel-BG.png'
// import bannerImageLight from '../../assets/images/GOH-Lifestyle.jpg'
// import familyImage from '../../assets/images/IMG_9607.jpg'
// import VideoRHR from '../../assets/video/U3RheWluZyBhdCBTdW1taXQgSG90ZWwgVGFjbG9iYW4ubXA0.mp4'

import { HeroBannerI } from '../../interface'

import './HomeMainCarousel.scss';
import { HEROBANNERTYPE, STATUS, PLATFORM } from '../../enum';

interface VideoIndex {
      id: number
}

const HomeMainCarousel = ({ data }) => {

      const refs = useRef([])
      const [videoIndex] = useState<VideoIndex[]>([])
      const [heroBanners, setHeroBanners] = useState<HeroBannerI[]>();
      const [bannerLength, setBannerLength] = useState<boolean>(false);

      useEffect(() => {
            setHeroBanners(data.tarsiergraphql.getHeroBanners);
            setBannerLength(heroBanners?.filter((banner) => banner.status === STATUS.PUBLISHED).length === 0);
      }, [heroBanners])

      const generateRefs = (element, index) => {
            refs.current[index] = element;
            videoIndex[index] = { id: index }
      }

      const playVideo = (c) => {
            if (typeof window !== "undefined")
                  document.querySelectorAll('.unmute').forEach(el => {
                        el.classList.remove("active")
                  })

            if (videoIndex[c])
                  if (c === videoIndex[c].id)
                        refs.current[c]?.play();
      }

      const pauseVideo = (c) => {
            if (typeof window !== "undefined")
                  document.querySelectorAll('.unmute').forEach(el => {
                        el.classList.remove("active")
                  })

            if (videoIndex)
                  videoIndex.map((v, i) => {
                        refs.current[i].muted = true;
                        refs.current[i]?.pause();
                  })
      }

      const toggleMute = (index) => {
            refs.current[index].muted = !refs.current[index].muted;

            if (typeof window !== "undefined")
                  if (!refs.current[index].muted) {
                        return document.getElementsByClassName(`video-${index}`)[0].classList.add("active");
                  }
            return document.getElementsByClassName(`video-${index}`)[0].classList.remove("active");

      }

      return (
            <div className="homepage-carousel carousel-dots-custom">
                  <Carousel
                        autoplay
                        arrows
                        autoplaySpeed={5000}
                        beforeChange={current => pauseVideo(current)}
                        afterChange={current => playVideo(current)}>
                        {heroBanners?.filter((banner) => banner.status === STATUS.PUBLISHED).map((banner, index) => (
                              <div key={index} className={`slider ${lowerCase(banner.media_type)}`}>

                                    {banner.media_hero_banner.filter((media) => media.platform === PLATFORM.WEBSITE).map((media, index) => (
                                          <>
                                                {media.media_type === HEROBANNERTYPE.IMAGE
                                                      && (<img src={media.url} alt="Banner Image" className="overlay-banner" />)}
                                                {media.media_type === HEROBANNERTYPE.IMAGE && (<img src={bannerImage} alt="Banner Image" />)}
                                                {media.media_type === HEROBANNERTYPE.VIDEO && (
                                                      <video
                                                            loop
                                                            autoPlay={true}
                                                            muted={true}
                                                            src={media.url}
                                                            type="video/mp4"
                                                            ref={(element) => generateRefs(element, index)}
                                                      />
                                                )}
                                                {media.media_type === HEROBANNERTYPE.VIDEO && (<div className="unmute video-1" onClick={() => toggleMute(index)}></div>)}
                                          </>
                                    ))}

                                    <div className="hotel-details">
                                          <p>{banner.title}</p>
                                          <p>{banner.description}</p>
                                          <p>{banner.location}</p>
                                    </div>
                              </div>
                        ))}
                        {bannerLength && (
                              <div className="slider image">
                                    <img src={bannerOverlay} alt="Banner Image" className="overlay-banner" />
                                    <img src={bannerImage} alt="Banner Image" />
                                    <div className="hotel-details">
                                          <p>NUSTAR RESORT AND CASINO</p>
                                          <p>Hotel Casino & Resort</p>
                                          <p>Cebu</p>
                                    </div>
                              </div>
                        )}
                  </Carousel>
            </div>
      )
}

export default HomeMainCarousel