import React from "react"
import { Row, Col, Carousel } from "antd"

import AppFeaturedPhoto from "../../assets/images/app-featured-section.jpg"

import "./FeaturedContents.scss"

const FeaturedContents = () => {
  return (
    <div className="featured-content-container">
      <Row className="paddingpX50">
        <Col xl={12} xs={23} className="image-featured">
          <Carousel
            autoplay
            autoplaySpeed={5000}
            className="image-featured-carousel carousel-dots-custom"
          >
            <img
              src={AppFeaturedPhoto}
              className="img-responsive"
              alt="Image Feature"
            />
          </Carousel>
        </Col>
        <Col xl={12} xs={21} className="featured-content-details">
          <div>
            <h2 className="title-border">Robinsons Hotels Mobile App</h2>
            <div className="description">
              <p>
                Book, scan, and tap your way into an enhanced travel experience
                with the Robinsons Hotels Mobile App:
              </p>
              <ul>
                <li>
                  Explore what our 24 hotel properties across the Philippines
                  can offer
                </li>
                <li>Easily check room availability</li>
                <li>Avail App-exclusive offers</li>
                <li>Customize your stay with add-ons</li>
                <li>Pay for your booking seamlessly</li>
                <li>
                  Earn and Pay using your Go Rewards points for your stays
                </li>
                <li>Check-in faster with our Self Check-In Kiosks</li>
              </ul>
              <p>
                Click the button below or scan the QR code to download the app
                now. Your next getaway is just a click away!
              </p>
            </div>
            <a
              href="https://uqr.to/1vmkb"
              target="_blank"
              className="rhr-button"
            >
              DOWNLOAD NOW
            </a>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default FeaturedContents
